import request from '@/common/request'

class PermissaoService {
  buscarTudo() {
    return request.get('/permissoes')
  }

  cadastrar(data) {
    return request.post('/permissoes', data)
  }

  excluir(id) {
    return request.delete(`/permissoes/${id}`)
  }

  adicionarRelacionamentoRotina(permissaoId, data) {
    return request.put(`/permissoes/${permissaoId}/rotinas`, data)
  }

  buscarRotinasDaPermissao(permissaoId) {
    return request.get(`/permissoes/${permissaoId}/rotinas`)
  }

  buscarRotinasPermitidasPeloUsuario() {
    return request.get('/permissoes/rotinas')
  }

  removerRelacionamentoRotina(permissaoId, rotinaId) {
    return request.delete(`permissoes/${permissaoId}/rotinas/${rotinaId}`)
  }
}

export default new PermissaoService()
