<template>
  <v-dialog v-model="showDialog" fullscreen hide-overlay transition="dialog-bottom-transition">
    <v-card>
      <v-toolbar flat dark color="primary">
        <v-btn icon dark @click="fecharDialogConfiguracao">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Perfil {{ perfilParams.nome }}</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-card-text>
        <v-data-table
          :headers="headersPermissao"
          :items="permissoes"
          :search="search"
          locale="pt-BR"
          sort-by="nome"
          class="elevation-1 mt-10"
        >
          <template #top>
            <v-toolbar flat color="white">
              <v-toolbar-title>Permissões</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-dialog v-model="dialogAdd" max-width="550px">
                <template #activator="{ on, attrs }">
                  <v-btn color="primary" class="mb-2" v-bind="attrs" v-on="on"> Adicionar </v-btn>
                </template>
                <v-card>
                  <v-card-title>
                    <span class="headline">Vincular Permissão com Perfil</span>
                  </v-card-title>

                  <v-card-text>
                    <v-form ref="form" v-model="validForm" @submit.prevent>
                      <v-container>
                        <v-row>
                          <v-col cols="12">
                            <v-select
                              v-model="editedItem.id"
                              :items="itensPermissao"
                              label="Permissão"
                              required
                              :rules="[obrigatorio]"
                            ></v-select>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12">
                            <v-alert v-if="showMessageError" dense outlined type="error">
                              {{ messageError }}
                            </v-alert>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-form>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="red darken-1" text @click="dialogAdd = false">Cancelar</v-btn>
                    <v-btn
                      color="green darken-1"
                      text
                      :disabled="!validForm"
                      @click="salvarRelacionamentoPermissao"
                      >Salvar</v-btn
                    >
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
            <v-toolbar flat class="pt-3 mb-3">
              <v-col cols="12" sm="12" md="6" class="pl-0">
                <v-text-field v-model="search" label="Buscar" class="mx-4"></v-text-field>
              </v-col>
            </v-toolbar>
          </template>

          <template #[`item.acoes`]="{ item }">
            <v-tooltip top>
              <template #activator="{ on }">
                <v-icon small class="mr-2" v-on="on" @click="removerPermissao(item)">
                  mdi-delete
                </v-icon>
              </template>
              <span>Excluir</span>
            </v-tooltip>
          </template>

          <template #no-data>
            <span>Sem registros</span>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>

    <SnackbarMessage
      :show-snack.sync="snackbar.showSnackParam"
      :snack-text="snackbar.snackTextParam"
      :snack-type="snackbar.snackTypeParam"
    />
  </v-dialog>
</template>

<script>
import PerfilService from '@/services/usuario/perfil'
import PermissaoService from '@/services/usuario/permissao'
import SnackbarMessage from '@/components/ui/SnackbarMessage'
import Snackbar from '@/models/ui/snackbar'
import { obrigatorio } from '@/shareds/regrasForm'

export default {
  components: {
    SnackbarMessage
  },

  props: ['showDialogConfiguracao', 'perfilParams'],

  data: () => ({
    headersPermissao: [
      { text: 'Nome', value: 'nome' },
      { text: '', value: 'acoes', width: '1%', sortable: false }
    ],
    search: '',
    permissoes: [],
    itensPermissao: [],
    itensAcoes: [
      { text: 'Acessar', value: 'ACESSAR' },
      { text: 'Adicionar', value: 'ADICIONAR' },
      { text: 'Editar', value: 'EDITAR' },
      { text: 'Remover', value: 'REMOVER' }
    ],
    showDialog: false,
    dialogAdd: false,
    dialogAddRotina: false,
    dialogDelete: false,
    validForm: true,
    snackbar: new Snackbar(),
    messageError: '',
    editedItem: [],
    defaultItem: [],
    showMessageError: false,
    obrigatorio: obrigatorio
  }),

  watch: {
    showDialogConfiguracao() {
      return this.showDialogConfiguracao ? this.buscarInformacoes(this.perfilParams.id) : ''
    }
  },

  methods: {
    buscarInformacoes() {
      this.showDialog = true
      this.permissoes = this.perfilParams.permissoes ? this.perfilParams.permissoes : []
      this.buscarOpcoesPermissao()
    },

    buscarOpcoesPermissao() {
      PermissaoService.buscarTudo()
        .then((response) => {
          this.itensPermissao = response.map((item) => ({
            text: item.nome,
            value: item.id
          }))
        })
        .catch((erro) => {
          this.snackbar.snackTextParam = `${erro.response.status} | ${
            erro.response.data.message === undefined
              ? erro.message
              : erro.response.data.message
              ? erro.response.data.message
              : 'Ocorreu um erro ao buscar as permissões.'
          }`
          this.snackbar.snackTypeParam = 'error'
          this.snackbar.showSnackParam = true
        })
    },

    fecharDialogConfiguracao() {
      this.permissoes = []
      this.showDialog = false
      this.$emit('update:showDialogConfiguracao', false)
    },

    fecharDialogInclusaoPermissao() {
      this.dialogAdd = false
      this.showMessageError = false
      this.messageError = ''
      this.$nextTick(() => {
        this.editedItem = { ...this.defaultItem }
      })
    },

    removerPermissao(item) {
      PerfilService.removerRelacionamentoPermissao(this.perfilParams.id, item.id)
        .then(() => {
          const index = this.permissoes.indexOf(item)
          this.snackbar.snackTextParam = 'Permissão removida do relacionamento com sucesso.'
          this.snackbar.snackTypeParam = 'sucess'
          this.snackbar.showSnackParam = true
          this.permissoes.splice(index, 1)
        })
        .catch((error) => {
          this.messageError = `${error.response.status} | ${
            error.response.data.message === undefined ? error.message : error.response.data.message
          }`
          this.showMessageError = true
        })
    },

    salvarRelacionamentoPermissao() {
      PerfilService.adicionarRelacionamentoPermissao(this.perfilParams.id, this.editedItem.id)
        .then((response) => {
          this.fecharDialogInclusaoPermissao()
          this.snackbar.snackTextParam = 'Permissão relacionada com sucesso.'
          this.snackbar.snackTypeParam = 'sucess'
          this.snackbar.showSnackParam = true
          this.permissoes.push(response)
        })
        .catch((error) => {
          this.messageError = `${error.response.status} | ${
            error.response.data.message === undefined ? error.message : error.response.data.message
          }`
          this.showMessageError = true
        })
    }
  }
}
</script>
