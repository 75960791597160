import request from '@/common/request'

class PerfilService {
  buscarTudo() {
    return request.get('/perfis')
  }

  create(data) {
    return request.post('/perfis', data)
  }

  update(id, ativo) {
    return request.put(`/perfis/${id}`, { ativo })
  }

  deleteById(id) {
    return request.delete(`/perfis/${id}`)
  }

  buscarPermissoesPeloPerfil(id) {
    return request.get(`/perfis/${id}/permissoes`)
  }

  adicionarRelacionamentoPermissao(perfilId, permissaoId) {
    return request.put(`/perfis/${perfilId}/permissoes/${permissaoId}`)
  }

  removerRelacionamentoPermissao(perfilId, permissaoId) {
    return request.delete(`/perfis/${perfilId}/permissoes/${permissaoId}`)
  }
}

export default new PerfilService()
