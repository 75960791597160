<template>
  <v-container fluid>
    <v-card class="mt-2">
      <v-container fluid>
        <v-data-table
          :headers="headers"
          :loading="buscando"
          loading-text="Carregando os perfis..."
          :items="perfis"
          item-class="class"
          :footer-props="{ itemsPerPageOptions: [5, 10, 15, 30, 50], showFirstLastPage: true }"
        >
          <template #top>
            <v-container fluid class="d-flex">
              <v-toolbar-title> Perfis </v-toolbar-title>
              <v-spacer />

              <v-dialog v-model="dialog" max-width="550px">
                <template #activator="{ on, attrs }">
                  <v-btn color="primary" v-bind="attrs" :disabled="!permiteAdicionar" v-on="on">
                    Adicionar
                  </v-btn>
                </template>

                <v-card>
                  <v-card-title>
                    <span class="headline"> Adicionar Perfil </span>
                  </v-card-title>

                  <v-card-text>
                    <v-form ref="form" v-model="validForm" @submit.prevent>
                      <v-container>
                        <v-row>
                          <v-col cols="12" sm="12" md="12">
                            <v-text-field
                              v-model="perfil.nome"
                              label="Nome"
                              required
                              :rules="[obrigatorio]"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12" sm="12" md="12">
                            <v-alert v-if="showMessageError" dense outlined type="error">
                              {{ messageError }}
                            </v-alert>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-form>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="red darken-1" text @click="fecharDialog"> Cancelar </v-btn>
                    <v-btn color="green darken-1" text :disabled="!validForm" @click="salvarPerfil">
                      Salvar
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-container>
          </template>

          <template #[`item.ativo`]="{ item }">
            <v-icon v-if="item.ativo" color="green">mdi-check</v-icon>
            <v-icon v-else color="red">mdi-close</v-icon>
          </template>

          <template #[`item.acoes`]="{ item }">
            <div class="d-flex flex-nowrap justify-center">
              <v-tooltip top>
                <template #activator="{ on }">
                  <v-icon
                    class="mr-2"
                    :disabled="!permiteEditar"
                    v-on="on"
                    @click="configurarPerfil(item)"
                  >
                    mdi-cog
                  </v-icon>
                </template>

                <span> Configurar </span>
              </v-tooltip>
            </div>
          </template>
        </v-data-table>
      </v-container>

      <SnackbarMessage
        :show-snack.sync="snackbar.showSnackParam"
        :snack-text="snackbar.snackTextParam"
        :snack-type="snackbar.snackTypeParam"
      />

      <PermissaoPerfil
        :show-dialog-configuracao.sync="showDialogConfiguracao"
        :perfil-params="perfilParams"
      />
    </v-card>
  </v-container>
</template>

<script>
import PerfilService from '@/services/usuario/perfil'
import Snackbar from '@/models/ui/snackbar'
import SnackbarMessage from '@/components/ui/SnackbarMessage'
import PermissaoPerfil from '@/components/aplicacao/permissoes/PermissaoPerfil'
import { obrigatorio, emailInvalido } from '@/shareds/regrasForm'
import store from '@/store'

export default {
  name: 'Perfil',

  components: {
    SnackbarMessage,
    PermissaoPerfil
  },

  data: () => ({
    perfis: [],
    itensPerfil: [],
    headers: [
      { text: 'Nome', value: 'nome' },
      { text: '', value: 'acoes', width: '1%' }
    ],
    panel: true,
    buscando: false,
    dialog: false,
    validForm: true,
    obrigatorio: obrigatorio,
    emailInvalido: emailInvalido,
    showMessageError: false,
    showDialogConfiguracao: false,
    perfil: {},
    perfilParams: {
      id: '',
      nome: ''
    },
    snackbar: new Snackbar()
  }),

  computed: {
    permiteAdicionar() {
      return store.getters.verificaPermissaoAcesso('Perfil', 'ADICIONAR')
    },
    permiteEditar() {
      return store.getters.verificaPermissaoAcesso('Perfil', 'EDITAR')
    }
  },

  created() {
    this.buscarPerfis()
  },

  methods: {
    buscarPerfis() {
      this.buscando = true

      PerfilService.buscarTudo()
        .then((response) => (this.perfis = response))
        .catch((erro) => {
          this.snackbar.snackTextParam = `${erro.response.status} | ${
            erro.response.data.message === undefined
              ? erro.message
              : erro.response.data.message
              ? erro.response.data.message
              : 'Ocorreu um erro ao buscar os perfis.'
          }`
          this.snackbar.snackTypeParam = 'error'
          this.snackbar.showSnackParam = true
        })
        .finally(() => (this.buscando = false))
    },

    configurarPerfil(item) {
      this.perfilParams = {
        id: item.id,
        nome: item.nome,
        permissoes: item.permissoes
      }
      this.showDialogConfiguracao = true
    },

    salvarPerfil() {
      this.showMessageError = false
      this.$refs.form.validate()

      if (!this.validForm) return

      PerfilService.create(this.perfil)
        .then((response) => {
          this.fecharDialog()
          this.snackbar.snackTextParam = 'Perfil cadastrado com sucesso.'
          this.snackbar.snackTypeParam = 'sucess'
          this.snackbar.showSnackParam = true
          this.perfis.push(response)
        })
        .catch((erro) => {
          this.messageError = `${erro.response.status} | ${
            erro.response.data.message === undefined
              ? erro.message
              : erro.response.data.message
              ? erro.response.data.message
              : 'Ocorreu um erro ao cadastrar o perfil.'
          }`
          this.showMessageError = true
        })
    },

    fecharDialog() {
      this.$refs.form.resetValidation()
      this.dialog = false
      this.showMessageError = false
      this.$nextTick(() => {
        this.perfil = { ...this.defaultItem }
      })
    }
  }
}
</script>
