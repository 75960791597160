var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"fullscreen":"","hide-overlay":"","transition":"dialog-bottom-transition"},model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}},[_c('v-card',[_c('v-toolbar',{attrs:{"flat":"","dark":"","color":"primary"}},[_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":_vm.fecharDialogConfiguracao}},[_c('v-icon',[_vm._v("mdi-close")])],1),_c('v-toolbar-title',[_vm._v("Perfil "+_vm._s(_vm.perfilParams.nome))]),_c('v-spacer')],1),_c('v-card-text',[_c('v-data-table',{staticClass:"elevation-1 mt-10",attrs:{"headers":_vm.headersPermissao,"items":_vm.permissoes,"search":_vm.search,"locale":"pt-BR","sort-by":"nome"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"white"}},[_c('v-toolbar-title',[_vm._v("Permissões")]),_c('v-spacer'),_c('v-dialog',{attrs:{"max-width":"550px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mb-2",attrs:{"color":"primary"}},'v-btn',attrs,false),on),[_vm._v(" Adicionar ")])]}}]),model:{value:(_vm.dialogAdd),callback:function ($$v) {_vm.dialogAdd=$$v},expression:"dialogAdd"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v("Vincular Permissão com Perfil")])]),_c('v-card-text',[_c('v-form',{ref:"form",on:{"submit":function($event){$event.preventDefault();}},model:{value:(_vm.validForm),callback:function ($$v) {_vm.validForm=$$v},expression:"validForm"}},[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{attrs:{"items":_vm.itensPermissao,"label":"Permissão","required":"","rules":[_vm.obrigatorio]},model:{value:(_vm.editedItem.id),callback:function ($$v) {_vm.$set(_vm.editedItem, "id", $$v)},expression:"editedItem.id"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[(_vm.showMessageError)?_c('v-alert',{attrs:{"dense":"","outlined":"","type":"error"}},[_vm._v(" "+_vm._s(_vm.messageError)+" ")]):_vm._e()],1)],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"red darken-1","text":""},on:{"click":function($event){_vm.dialogAdd = false}}},[_vm._v("Cancelar")]),_c('v-btn',{attrs:{"color":"green darken-1","text":"","disabled":!_vm.validForm},on:{"click":_vm.salvarRelacionamentoPermissao}},[_vm._v("Salvar")])],1)],1)],1)],1),_c('v-toolbar',{staticClass:"pt-3 mb-3",attrs:{"flat":""}},[_c('v-col',{staticClass:"pl-0",attrs:{"cols":"12","sm":"12","md":"6"}},[_c('v-text-field',{staticClass:"mx-4",attrs:{"label":"Buscar"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)]},proxy:true},{key:"item.acoes",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.removerPermissao(item)}}},on),[_vm._v(" mdi-delete ")])]}}],null,true)},[_c('span',[_vm._v("Excluir")])])]}},{key:"no-data",fn:function(){return [_c('span',[_vm._v("Sem registros")])]},proxy:true}],null,true)})],1)],1),_c('SnackbarMessage',{attrs:{"show-snack":_vm.snackbar.showSnackParam,"snack-text":_vm.snackbar.snackTextParam,"snack-type":_vm.snackbar.snackTypeParam},on:{"update:showSnack":function($event){return _vm.$set(_vm.snackbar, "showSnackParam", $event)},"update:show-snack":function($event){return _vm.$set(_vm.snackbar, "showSnackParam", $event)}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }